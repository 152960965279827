<template>
     <!-- 待审核 -->
    <div class="content fs14 bs_b pl24 pr24 pt1 pb24">
        <div class="title">商品状态</div>
        <div class="status-box">
            <div class="mb10">
                <span class="sub-name">商品状态：</span>
                <span  class="fs16 c1991FC">{{ spuInfo.spuStatus }}</span>
            </div>

            <div class="mb10">
                <span class="sub-name">供应商账户：{{userInfo.userName}}</span>
                <span style="padding-left:150px;" class="sub-name">供应商编码：{{userInfo.supplyCode}}</span>
            </div>
            <div class="">
                <span class="sub-name">供应商名称：{{userInfo.companyName}}</span>
            </div>
        </div>

        <div class="title">商品详情</div>
        <el-tabs v-model="activeName">
            <el-tab-pane label="基本信息" name="1"></el-tab-pane>
            <el-tab-pane label="图文表述" name="2"></el-tab-pane>
        </el-tabs>
        <!-- 基本信息 -->
        <div v-if="activeName =='1'">
            <div class="title">基本信息</div>
            <div class="flex flex_wrap">
                <div class="mr100">
                    <div class="basic-name">商品名称</div>
                    <div class="basic-main">{{ spuInfo.spuName }}</div>
                </div>
                <div class="mr100">
                    <div class="basic-name">SPU编码</div>
                    <div class="basic-main">{{ spuInfo.spuCode }}</div>
                </div>
                <div class="mr100">
                    <div class="basic-name">平台类目</div>
                    <div class="basic-main">{{ spuInfo.category3Name }}</div>
                </div>
                <div class="mr100">
                    <div class="basic-name">品牌</div>
                    <div class="basic-main">{{ spuInfo.brandName }}</div>
                </div>
                <div class="mr100">
                    <div class="basic-name">商品副标题</div>
                    <div class="basic-main">{{ spuInfo.spuSubTitle }}</div>
                </div>
                <div class="mr100">
                    <div class="basic-name">商品税率</div>
                    <div class="basic-main">{{ spuInfo.rate }}%</div>
                </div>
            </div>
             <!-- <div>
                <div class="basic-name">下单方式</div>
                <div class="basic-main">
                    <i class="el-icon-success mr3" style="color: #0CB65F;"></i>
                    <span>立即购买</span>
                    <i class="el-icon-success mr3 ml12" style="color: #0CB65F;"></i>
                    <span>加入购物车</span>
                </div>
            </div> -->
            <div class="flex flex_wrap">
                <div class="mr100">
                    <div class="basic-name">商品图片</div>
                    <div class="basic-main">
                        <el-image 
                            v-for="item in spuImage"
                            :key="item.id"
                            class="commodity-img mr10"
                            style="border:1px solid #F7F7F7;width: 120px; height: 120px"
                            :src="item.picUrl" 
                            :preview-src-list="[item.picUrl]">
                        </el-image>
                        <!-- <img v-for="item in spuImage" :key="item.id" class="commodity-img mr10" :src="item.picUrl" alt=""> -->
                    </div>
                </div>
                <div>
                    <div class="basic-name">商品视频</div>
                    <div class="basic-main">
                        <video v-if="spuVideo.length > 0 && spuVideo[0].videoUrl"
                            v-bind:src="spuVideo[0].videoUrl"
                            style="border:1px solid #F7F7F7;width: 120px; height: 120px"
                            class="avatar video-avatar"
                            controls="controls">
                            您的浏览器不支持视频播放
                        </video>
                        <!-- <img v-for="item in spuVideo" :key="item.id" class="commodity-img mr10" :src="item.videoUrl" alt=""> -->
                        <!-- <img class="commodity-img mr10" src="../../assets/images/common/mnLogo.png" alt=""> -->
                    </div>
                </div>
            </div>
            <div class="title">规格信息</div>
             <el-table
                :data="skuDataList"
                style="margin-bottom: 20px;"
                row-key="id"
                :header-cell-style="tableHeaderColor"
                >

                <el-table-column
                v-for="(item,index) in skuHeader" :key="index"
                :prop="`skuSpec${index}`"
                :label="item">
                </el-table-column>

                <el-table-column
                prop="skuCode"
                label="SKU编码">
                </el-table-column>
                <el-table-column
                prop="skuPrice"
                label="价格">
                </el-table-column>
                <el-table-column
                prop="skuWeight"
                label="重量（kg）">
                </el-table-column>
                <el-table-column
                prop="skuUnit"
                label="单位">
                </el-table-column>
                <el-table-column
                prop="skuStockNum"
                label="库存">
                </el-table-column>
            </el-table> 
     

            <div class="title">商品属性</div>
            <div class="flex flex_wrap">
                
                <div v-for="item in spuAttrGroups" class="spu-group-div-cls">
                    <div class="group-name-div-cls">{{item.name}}</div>
                    <div v-for="attr in item.attrs" class="attr-div-cls">{{attr.attrName}}：{{attr.attrValue}}</div>
                </div>

            </div>

            <div class="title">运费信息</div>
            <div v-for="item in freightList" style="padding-top:4px;padding-bottom: 4px;">
                <i class="el-icon-success mr5" style="color: #0CB65F;"></i>
                <span  class="mr8">{{item.freightContent}}</span>
            </div>
        </div>
        <!-- 图文表述 -->
        <div  v-if="activeName =='2'" v-html="spuInfo.spuDesc">
            {{ spuInfo.spuDesc }}
        </div>
        <div class="zw mt50"></div>
        <div class="btn-box fs14 flex f_jc_c f_ai_c" :style="{'width': width+'px'}">
            <div class="return cp"  @click="returnGo">返回</div>
            <!-- <div v-if="spuStatus == 2" class="pass cp"  @click="updateSpuStatus(2)">上架</div> -->
            <div v-if="spuStatus == 1 || spuStatus == 0" class="reject cp"  @click="updateSpuStatus(1)">下架</div>
        </div>
    </div>
   
</template>

<script>
export default {
    name: '',
    data(){
        return{
            id: this.$route.query.id,
            activeName: '1',
            skuDataList: [],
            width: 0,
            userInfo: {
                userName:'',
                companyName:'',
                supplyCode:''
            },
            spuStatus:'',
            spuInfo:{
                spuName:'', //商品名称
                spuCode:'', //商品编码
                spuStatus:'', //状态
                category3Name:'', // 类目名称
                brandName:'', // 品牌名称
                spuSubTitle:'', //商品副标题
                rate:'', // 商品税率
                spuDesc:'' //商品长描述
            },
            commoditySpuAttrInsBOList:[], //商品属性
            commoditySpuSpecValueBOList:[], //规格属性
            spuImage:[],
            spuVideo:[],
            skuHeader:[],//商品规格表头
            spuAttrGroups:[],//商品属性组
            freightList:[]
        }
    },

    mounted(){
        this.viewInfo();
        this.width = document.body.offsetWidth - 206 - 48;
    },
    methods:{
        tableHeaderColor({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 0) {
                return 'background-color: #F9F9F9;font-weight: 500;'
            }
        },
        viewInfo() {
            //查询商品详情
            this.qa.querySpuDetailsByCommoditySpuId({id: this.id}).then(res => {
                if(res.respCode == "0000") {
                    this.spuInfo.spuName = res.data.spuName
                    this.spuInfo.spuCode = res.data.spuCode
                    this.spuInfo.brandName = res.data.brandName
                    this.spuInfo.spuSubTitle = res.data.spuSubTitle
                    this.spuInfo.rate = res.data.rate
                    this.spuStatus = res.data.spuStatus
                    this.spuInfo.spuDesc = res.data.spuDesc
                    if(res.data.spuStatus === '1'){
                        this.spuInfo.spuStatus =  '已上架'
                    }else if(res.data.spuStatus === '2'){
                        this.spuInfo.spuStatus =  '已下架'
                    }else if(res.data.spuStatus === '0'){
                        this.spuInfo.spuStatus =  '草稿（待上架）'
                    }

                    if(res.data.commoditySpuPicBOList.length > 0){
                        this.spuImage = res.data.commoditySpuPicBOList
                    }

                    if(res.data.commoditySpuVideoBOList.length > 0){
                        this.spuVideo = res.data.commoditySpuVideoBOList
                    }

                    if(res.data.commoditySpuAttrInsBOList.length > 0){
                        this.commoditySpuAttrInsBOList = res.data.commoditySpuAttrInsBOList
                    }
                    if(res.data.commoditySpuSpecValueBOList.length > 0){
                        this.commoditySpuSpecValueBOList = res.data.commoditySpuSpecValueBOList
                    }

                    //构建商品规格表头
                    this.commoditySpuSpecValueBOList.forEach((_spuSpecValueBO)=>{
                        if(this.skuHeader.indexOf(_spuSpecValueBO.commoditySpuSpecName)==-1){
                            this.skuHeader.push(_spuSpecValueBO.commoditySpuSpecName);
                        }
                    });
                    //构建商品规格数据
                    res.data.commoditySkuBOList.forEach((_commoditySkuBO)=>{
                        _commoditySkuBO.skuPrice = this.highPrecisionDiv( _commoditySkuBO.skuPrice,10000);
                        var _headerIndex = 0;
                        this.skuHeader.forEach((_skuHeaderItem)=>{
                            _commoditySkuBO.commoditySkuSpecValBOList.forEach((_commoditySkuSpecValBO)=>{
                                if(_skuHeaderItem == _commoditySkuSpecValBO.skuSpecName){
                                    _commoditySkuBO["skuSpec"+ _headerIndex] = _commoditySkuSpecValBO.skuSpecValue;
                                    _headerIndex++;
                                }
                            });
                        });

                        this.skuDataList.push(_commoditySkuBO);
                    })
                    //构建商品属性组数据
                    this.commoditySpuAttrInsBOList.forEach((_spuAttrGroupBO)=>{
                        //排重属性组
                        var findGroup = this.spuAttrGroups.find((_spuAttrGroupTmp)=>{
                            if(_spuAttrGroupTmp.name == _spuAttrGroupBO.commodityCategoryAttrGroupName){
                                return true;
                            }
                        })
                        if(findGroup == undefined){
                            var _tmpGroup = {
                                name:_spuAttrGroupBO.commodityCategoryAttrGroupName,
                                attrs:[]
                            }
                            this.commoditySpuAttrInsBOList.forEach((_commoditySpuAttrInsBO)=>{
                                if(_spuAttrGroupBO.commodityCategoryAttrGroupName == _commoditySpuAttrInsBO.commodityCategoryAttrGroupName){
                                    _tmpGroup.attrs.push(_commoditySpuAttrInsBO);
                                }
                            });
                            this.spuAttrGroups.push(_tmpGroup);
                        }
                    });

                     
                    //类目list
                    this.qa.commodityCategoryQueryForList({}).then(res2 => {
                        if(res2.respCode == "0000") {
                            if(res.data.categoryIds != null && res.data.categoryIds.length > 0){
                                res.data.categoryIds.forEach(element => {
                                    res2.data.forEach(item => {
                                        if(element === item.id){
                                            if(this.spuInfo.category3Name === ''){
                                                this.spuInfo.category3Name = item.categoryName
                                            }else{
                                                this.spuInfo.category3Name += '>' + item.categoryName
                                            }
                                        }
                                    });
                                });
                                
                            }
                        }
                    })

                    //查询运费信息
                    if(res.data.shippingType == "1"){
                        this.freightList = [{freightContent:"包邮"}];
                    }else if(res.data.shippingType == "2"){
                        this.freightList = [{freightContent:"统一运费：" + res.data.freightPrice + "元"}];
                    }else if(res.data.shippingType == "3"){
                        this.qa.userFreightTemplateQueryFreightTemplateById({id:res.data.shippingTamplateId}).then(res=>{
                            if(res.respCode == "0000") {
                                res.data.freightTemplateDetailBOList.forEach((_freightTemplateDetailBO)=>{
                                    var freightContent = "配送地区：" + (_freightTemplateDetailBO.regionId == null ? "未设置的配送地区" : _freightTemplateDetailBO.regionName);
                                    freightContent += "，计费规则：" ;
                                    if(_freightTemplateDetailBO.ruleType == "1"){
                                        freightContent += "订单不满" + _freightTemplateDetailBO.orderMoney + "元, 收取运费" + _freightTemplateDetailBO.regionFreightPrice + "元";
                                    }
                                    if(_freightTemplateDetailBO.ruleType == "2"){
                                        freightContent += "收取固定运费" + _freightTemplateDetailBO.regionFreightPrice + "元";
                                    }
                                    if(_freightTemplateDetailBO.ruleType == "3"){
                                        freightContent += "包邮";
                                    }
                                    this.freightList.push({freightContent:freightContent});
                                });
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    }
                    

                } else {
                    this.$message.error(res.msg);
                }
            })

            //查看商品卖家信息
            const formData = new FormData();
            formData.set("id",this.$route.query.shopId)
            this.qa.shopmallUserInfoQueryById(formData).then(res => {
                if(res.respCode == "0000") {
                    this.userInfo.userName = res.data.userName
                    this.userInfo.companyName = res.data.companyName
                    this.userInfo.supplyCode = res.data.supplyCode
                } else {
                    this.$message.error(res.msg);
                }
            })

            
            
        },
        returnGo() {
            this.$router.push({
                path: '/commodityList'
            });
        },
        reject() {
        },
        pass() {

        },
        updateSpuStatus(type){
            if(type === 1){
                this.$confirm('是否确认下架商品?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                    this.qa.commoditySpuDoUpdate({id: this.id, spuStatus: 2}).then(res => {
                        this.$message({
                        message: '下架成功',
                        type: 'success'
                        });
                        this.spuInfo.spuStatus =  '已下架'
                    })
                    }).catch(() => {        
                });
            }else{

                this.$confirm('是否确认上架商品?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                    this.qa.commoditySpuDoUpdate({id: this.id, spuStatus: 1}).then(res => {
                        this.$message({
                        message: '上架成功',
                        type: 'success'
                        });
                        this.spuInfo.spuStatus =  '已上架'
                    })
                    }).catch(() => {        
                });
            }
        }
    }
}
</script>

<style scoped>
    div.spu-group-div-cls{
        width: 286px;
        height: 180px;
        background: #FAFAFA;
        border-radius: 2px;
        float: left;
        margin-right: 10px;
        padding: 14px;
    }
   div.spu-group-div-cls>div.group-name-div-cls{
        display: inline-block;
        font-size: 13px;
        padding-left: 10px;
        padding-right: 10px;
        color: #fff;
        height: 26px;
        line-height: 26px;
        background: #1991FC;
        border-radius: 2px;
    }
    div.spu-group-div-cls>div.attr-div-cls{
        color: rgba(29, 30, 31, 0.8);
        font-weight: 400;
        padding-top: 5px;
        font-size: 14px;
    }
    .title {
        font-size: 14px;
        color: #1D1E1F;
        margin: 24px 0 10px;
        font-weight: 600;
    }
    .status-box {
        width: 100%;
        background: rgba(25, 145, 252, 0.02);
        border-radius: 2px;
        border: 1px solid rgba(0, 145, 255, 0.2);
        box-sizing: border-box;
        padding: 24px;
    }

    .content >>> .el-tabs .is-active {
        background: rgba(25, 145, 252, 0.1);
    }
    .content >>> .el-tabs .el-tabs__item {
        /* padding: 0; */
        

    }
    .content >>> .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
        padding-left: 20px;
    }
    .content >>> .el-tabs--top .el-tabs__item.is-top:last-child {
        padding-right: 20px;
    }

    .basic-name {
        font-size: 12px;
        color: #9B9B9C;
        margin-bottom: 6px;
    }
    .basic-main {
        color: #1D1E1F;
        margin-bottom: 10px;
    }

    .commodity-img {
        width: 120px;
        height: 120px;
    }
    .zw {
        width: 100%;
        height: 44px;
    }
    .btn-box {
        position: fixed;
        bottom: 0;
        box-shadow: 0px -2px 6px 2px rgba(0, 0, 0, 0.06);
        width: 100%;
        height: 44px;
        background: #fff;
        z-index: 5;
        margin-left: -24px;
    }
    .btn-box div {
        width: 83px;
        text-align: center;
        height: 32px;
        line-height: 32px;
        box-sizing: border-box;    
    }
    .return {
        color: #A4A5A5;
        border: 1px solid #A4A5A5;
        background: #fff;
    }
    .reject {
        border: 1px solid #F53333;
        background: #F53333;
        margin: 0 15px;
        color: #fff;
    }
    .pass {
        border: 1px solid #1991FC;
        background: #1991FC;
        color: #fff;
    }
</style>